<!-- card table info -->
<template>
  <div class="row justify-content-center" style="gap: 1rem">
    <div
      v-for="(card, index) in data"
      :key="index"
      class="col-12 col-sm-5 col-md d-flex justify-content-between align-items-center rounded p-2 glow-box bg-dark"
      :style="{
        boxShadow:
          activeCard === index ? `inset 0 0 0 2px ${card.color}` : 'none',
      }"
      @mouseenter="showCursorGlow(index)"
      @mouseleave="hideCursorGlow(index)"
      @mousemove="(event) => handleMouseMove(event, index)"
      @click="toggleActive(index)"
    >
      <!-- linar-style cursor glow -->
      <div
        v-if="showGlow"
        class="cursor-glow"
        :style="{
          left: cursorX[index] + 'px',
          top: cursorY[index] + 'px',
          ...glowStyle(card.color),
          opacity: showGlow[index] ? '1' : '0',
        }"
      ></div>
      <div
        :class="`d-flex align-items-center justify-content-center`"
        :style="{
          width: '60px',
          height: '60px',
          backgroundColor: card.color,
          'border-radius': computeBorderRadius(card.tipo),
          '-webkit-box-shadow': `0px -1px 33px -8px ${card.color}`,
          '-moz-box-shadow': `0px -1px 33px -8px ${card.color}`,
          'box-shadow': `0px -1px 33px -8px ${card.color}`,
        }"
      >
        <i
          :class="`fas fa-${card.iconName} ${computeAnimateIcon(
            card.animated,
            index
          )}`"
          style="font-size: 1.5rem"
        ></i>
      </div>
      <div
        class="d-flex flex-column align-items-center"
        style="flex-grow: 1; max-width: calc(100% - 60px)"
      >
        <div :class="`title-container`">
          <span
            :class="[
              showGlow[index] &&
              animatedState[index] &&
              shouldAnimateTitle[index]
                ? 'title-animate'
                : 'title-static',
            ]"
            class="font-weight-bold"
          >
            {{ card.title }}
          </span>
        </div>
        <span
          class="font-weight-bold"
          :style="{
            fontSize: `${card.sizeValue ?? 40}px`,
          }"
          >{{ evaluateValue(card.value) }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // Documentación del componente CardTable.js

  /**
   * Este componente Vue.js se utiliza para renderizar un conjunto de tarjetas de información.
   * Cada tarjeta es interactiva y muestra diferentes tipos de datos y un ícono.
   *
   * Propiedades:
   * - data: Un array de objetos que contiene la información para renderizar cada tarjeta.
   *
   * Estado interno:
   * - showGlow: Un objeto que controla si el efecto de brillo debe mostrarse en cada tarjeta.
   * - cursorX: Un objeto que contiene la coordenada X del cursor para cada tarjeta.
   * - cursorY: Un objeto que contiene la coordenada Y del cursor para cada tarjeta.
   * - animationFrameId: Un objeto que guarda el ID de la solicitud de animación para cada tarjeta.
   * - animatedState: Un objeto que guarda el estado de animación para cada tarjeta.
   * - activeCard: Un número que indica el índice de la tarjeta activa. Null si no hay ninguna tarjeta activa.
   * - shouldAnimateTitle: Un objeto que determina si el título de la tarjeta debe animarse para cada tarjeta.
   *
   * Métodos:
   * - toggleActive: Cambia el estado activo de una tarjeta.
   * - computeBorderRadius: Calcula el radio del borde según el tipo de tarjeta.
   * - computeAnimateIcon: Devuelve la clase de animación apropiada para el ícono.
   * - evaluateValue: Evalúa el valor que debe mostrarse en la tarjeta.
   * - glowStyle: Devuelve el estilo CSS para el efecto de brillo del cursor.
   * - handleMouseMove: Maneja el movimiento del mouse para el efecto de brillo del cursor.
   * - showCursorGlow: Activa el efecto de brillo del cursor.
   * - hideCursorGlow: Desactiva el efecto de brillo del cursor.
   * - onResize: Maneja el evento de cambio de tamaño de la ventana.
   * - checkTitleLength: Verifica la longitud del título para determinar si necesita animación.
   */

  props: {
    /*
       // Ejemplo de un objeto dentro del array 'data':
          {
          title: "Finalizadas",
          value: () => this.listaItems.filter((cap) => cap.estadoCap && cap.disponible).length,
          iconName: "calendar-check",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "shake",
          onActivate: () => { console.log("Tarjeta activada"); },
          onDeactivate: () => { console.log("Tarjeta desactivada"); }
        },
        */
    data: {
      type: Array,
      default: () => [
        {
          title: "Título",
          value: 0,
          sizeValue: 40,
          iconName: "crown",
          color: "rgba(223, 18, 46, 1)",
          tipo: "circle",
          animated: "shake",
          onActivate: null,
          onDeactivate: null,
        },
      ],
    },
  },
  data() {
    return {
      showGlow: {},
      cursorX: {},
      cursorY: {},
      animationFrameId: {},
      animatedState: {},
      activeCard: null,
      shouldAnimateTitle: {},
      animated: {
        beat: "fa-beat",
        beatFade: "fa-beat-fade",
        bounce: "fa-bounce",
        fade: "fa-fade",
        flip: "fa-flip",
        shake: "fa-shake",
        spin: "fa-spin",
        spinReverse: "fa-spin fa-spin-reverse",
        spinPulse: "fa-spin-pulse",
      },
      tipoIcon: {
        circle: "50%",
        squared: "20%",
        shield: "49% 51% 48% 52% / 29% 29% 71% 71%",
        water: "60% 40% 78% 22% / 73% 66% 34% 27%",
      },
    };
  },
  /*
Eventos de Ciclo de Vida:
- mounted: Se añade un escuchador para el evento de redimensionamiento de la ventana.
- beforeDestroy: Se elimina el escuchador para el evento de redimensionamiento de la ventana.
*/
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.data.forEach((_, index) => {
        this.checkTitleLength(index);
      });
    },
    checkTitleLength(index) {
      this.$nextTick(() => {
        const titleElement = this.$el.querySelectorAll(".title-container span")[
          index
        ];
        if (titleElement) {
          // console.log("Client Width: ", titleElement.clientWidth);
          // console.log("Scroll Width: ", titleElement.scrollWidth);

          if (titleElement.scrollWidth > titleElement.clientWidth) {
            this.$set(this.shouldAnimateTitle, index, true);
          } else {
            this.$set(this.shouldAnimateTitle, index, false);
          }
        }
      });
    },
    toggleActive(index) {
      // Desactivar tarjeta anterior si es diferente de la actual
      if (this.activeCard !== null && this.activeCard !== index) {
        if (typeof this.data[this.activeCard].onDeactivate === "function") {
          this.data[this.activeCard].onDeactivate();
        }
      }

      // Cambiar la tarjeta activa
      this.activeCard = this.activeCard === index ? null : index;

      // Ejecutar la función de activación o desactivación correspondiente
      if (this.activeCard !== null) {
        if (typeof this.data[this.activeCard].onActivate === "function") {
          this.data[this.activeCard].onActivate();
        }
      } else {
        if (typeof this.data[index].onDeactivate === "function") {
          this.data[index].onDeactivate();
        }
      }
    },
    computeBorderRadius(tipo) {
      return this.tipoIcon[tipo] || "20% 40% 20% 40%";
    },
    computeAnimateIcon(tipo, index) {
      if (this.animatedState[index]) {
        return this.animated[tipo] || "";
      }
    },
    evaluateValue(value) {
      if (typeof value === "function") {
        return value();
      }
      return value;
    },
    glowStyle(color) {
      return {
        boxShadow: `0 0 40px 20px ${color}`,
      };
    },
    handleMouseMove(event, index) {
      const rect = event.currentTarget.getBoundingClientRect();
      const targetX = event.clientX - rect.left;
      const targetY = event.clientY - rect.top;

      // Cancelar la animación anterior si está en curso
      if (this.animationFrameId[index]) {
        cancelAnimationFrame(this.animationFrameId[index]);
      }

      // Iniciar una nueva animación
      const animate = () => {
        const startX = this.cursorX[index] || 0;
        const startY = this.cursorY[index] || 0;
        const dx = (targetX - startX) * 0.1; // Factor de suavizado
        const dy = (targetY - startY) * 0.1; // Factor de suavizado

        this.$set(this.cursorX, index, startX + dx);
        this.$set(this.cursorY, index, startY + dy);

        if (Math.abs(dx) > 0.1 || Math.abs(dy) > 0.1) {
          // Continuar la animación
          this.animationFrameId[index] = requestAnimationFrame(animate);
        }
      };

      // Iniciar la animación
      this.animationFrameId[index] = requestAnimationFrame(animate);
    },
    showCursorGlow(index) {
      this.$set(this.animatedState, index, true);
      this.$set(this.showGlow, index, true);
    },
    hideCursorGlow(index) {
      this.$set(this.animatedState, index, false);
      this.$set(this.showGlow, index, false);
    },
  },
};
</script>

<style scoped>
/*
Estilos:
- glow-box: Configuración base para el efecto de resplandor.
- cursor-glow: Estilo para el resplandor del cursor.
- title-container: Contenedor para el título de la tarjeta.
- title-animate: Animación para el título de la tarjeta.
- title-static: Estilo estático para el título de la tarjeta.
*/

.glow-box {
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  cursor: default;
}

.cursor-glow {
  position: absolute;
  width: 0;
  height: 0;
  border-radius: 50%;
  pointer-events: none;
  transition: left 0.2s, top 0.2s, opacity 0.5s ease;
}

.title-container {
  max-width: calc(100% - 20px);
  overflow: hidden;
  height: 20px;
}

.title-animate {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 5s linear infinite;
  transition: transform 0.3s ease;
}

.title-static {
  display: inline-block;
  max-width: 100%; /* Se ajusta al tamaño del contenedor */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
