<template>
  <HXContentCard title="Reportes Iframe">
    <div class="row px-5 py-4" style="gap: 1rem">
      <div class="col-12 col-md-10">
        <!-- linar-style cursor glow -->
        <CardTable :data="dataCards" />
      </div>
      <!-- btn new capacitacion -->
      <div class="col-12 col-md">
        <BtnAddCardTable text="Agregar Nuevo Reporte" url="Reportes_agregar" />
      </div>
    </div>
    <div class="table-responsive px-4 mt-3">
      <table
        id="datatable_areas"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>Empresa</th>
            <th>Nivel Reporte</th>
            <th>Descripción</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in listaItems" :key="item.id">
            <td>
              {{ item.empresa.nombre }}
            </td>
            <td>
              {{ item.reportingLevel }}
            </td>
            <td>
              <p style="font-size: medium">
                {{ item.description }}
              </p>
            </td>
            <td class="d-flex justify-content-center">
              <!-- editar -->
              <vs-button
                v-b-tooltip.hover
                title="Editar"
                success
                :to="`Reportes_editar/${item.id}`"
              >
                <i class="fas fa-solid fa-pen"></i>
              </vs-button>
              <!-- eliminar -->
              <vs-button
                v-b-tooltip.hover
                title="Eliminar"
                danger
                @click="deleteRow(item.id)"
              >
                <i class="fas fa-solid fa-trash"></i>
              </vs-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </HXContentCard>
</template>
<script>
import BtnAddCardTable from "../../../components/btnCardTable/btnAddCardTable.vue";
import CardTable from "../../../components/cardTable/CardTable.vue";
import { core } from "../../../config/pluginInit";
const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  data() {
    return {
      listaItems: [],
      logoEmpresa: "",
      usuario: {},
      dataCards: [
        {
          title: "Empresas Reportes",
          value: () =>
            this.listaItems.reduce((acomulador, reporte) => {
              //contar empresas unicas
              if (!acomulador.includes(reporte.companyId)) {
                acomulador.push(reporte.companyId);
              }
              return acomulador;
            }, []).length,
          iconName: "building",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        {
          title: "Niveles Reportes",
          value: () =>
            this.listaItems.reduce((acomulador, reporte) => {
              // Contar niveles de reporte únicos
              if (!acomulador.includes(reporte.reportingLevel)) {
                acomulador.push(reporte.reportingLevel);
              }
              return acomulador;
            }, []).length,
          iconName: "clock",
          color: "rgba(254, 114, 28, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();
      core.index();
      window.$("#datatable_areas").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#186bdd",
        cancelButtonColor: "rgb(242, 19, 93)",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_post", {
            path: "PowerBI/Delete/" + id,
          });
          await this.getData();
          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PowerBI/GetList/",
        });
        console.log("Reports... ", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
  components: { CardTable, BtnAddCardTable },
};
</script>
<style lang="scss"></style>
